import React, { useState, useEffect, useRef } from 'react'
import {
    Slide,
    AppBar,
    Toolbar,
    Grid,
    Typography,
    Box,
    Container,
    Button,
    Card,
    CardHeader,
    CardContent,
    Checkbox,
    FormControlLabel,
    Stack,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import { Close, ArrowBackIos } from '@mui/icons-material';
import * as api from '../../api'
import { useLocation, useNavigate } from 'react-router-dom';
import liff from '@line/liff';
import { CardResponse } from '../../ui-component/'
import * as dict from '../../dictionary/PrivacyPolicy'
export const PrivacyPolicy = () => {

    const [userAcceptTerm, setUserAcceptTerm] = useState(false)
    const [consentList, setConsentList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [alertError, setAlertError] = useState({ msg: "", open: false })
    const [msgErrorConsent, setMsgErrorConsent] = useState({ msg: "", open: false })
    const [userLanguage, setUserLanguage] = useState("th");
    const [loading, setLoading] = useState(false);
    const isProcessing = useRef(false);
    const location = useLocation()
    const navigate = useNavigate()


    const handleClickNext = () => {

        if (currentPage === Object.keys(consentList)?.length) return

        let current = currentPage + 1
        setCurrentPage(current)
        setUserAcceptTerm(false)
    }

    const checkCustomerType = () => {
        let cid_rental = localStorage.getItem("cid_rental")
        if (cid_rental) {
            return "tenant"
        }
        return "buyer"
    }
    const handleSubmitRegister = async () => {
        if (isProcessing.current) return;
        isProcessing.current = true;
        let res = await api.register({
            "line_id": location.state.line_id,
            "citizen_id": location.state.citizen_id,
            "customer_type": checkCustomerType(),
            "is_accept": true,
            // "product_type": "remlite",
            "mobile_no": location.state.mobile_no,
            "contact_type": location.state.contact_type,
        })

        if (res.status !== 200) {
            isProcessing.current = false;
            return setAlertError({
                msg: res.response.data[`message_${location.state.language}`],
                open: true
            })
        }

        isProcessing.current = false;
        setAlertSuccess(true)
        return

    }


    useEffect(() => {

        const loadConsent = async () => {
            setLoading(true)
            let res = await api.getConsentAllByUser({
                "line_id": location.state.line_id,
                "citizen_id": location.state.citizen_id,
                "contact_type": location.state.contact_type
            })
            if (res.response?.data?.result === false) {
                setMsgErrorConsent({ msg: res.response?.data?.message || "Error not found", open: true })
                setLoading(false)
                return
            }

            if (res.status !== 200) {
                setMsgErrorConsent({ msg: res.response?.data?.message || "An error occurred while registering. เกิดข้อผิดพลาดขณะลงทะเบียน", open: true })
                setLoading(false)
                return
            }

            setConsentList(res?.data)
            setLoading(false)
        }

        setCurrentPage(1)
        setUserAcceptTerm(false)
        loadConsent()
        setUserLanguage(location.state.language)
    }, [location.state])

    useEffect(() => {
        const checkPreviousAcceptTemp = () => {

            let temp = sessionStorage.getItem(`pageAccept${currentPage}`)
            if (temp === "true") {
                return setUserAcceptTerm(true)
            }
            return setUserAcceptTerm(false)
        }
        checkPreviousAcceptTemp()
    }, [currentPage])

    useEffect(() => {

        if (userAcceptTerm === true) {
            return sessionStorage.setItem(`pageAccept${currentPage}`, "true")
        }

        return sessionStorage.setItem(`pageAccept${currentPage}`, "")

    }, [userAcceptTerm,currentPage])

    return (
        <>
            <AppBar sx={{
                position: 'relative',
                boxShadow: 'none',
                height: "8dvh",
            }}>
                <Toolbar>
                    <Grid container item xs={1}>
                        {
                            (currentPage === Object.keys(consentList)?.length && currentPage !== 1) && <ArrowBackIos onClick={() => {
                                setCurrentPage(currentPage - 1)

                            }} />
                        }

                        {
                            currentPage === 1 && <Close onClick={() => navigate(-1)} />
                        }

                    </Grid>
                    <Grid container item xs={10} direction="row"
                        justifyContent="center"
                    >
                        <Typography variant="h6" >
                            {dict.Term_Condition_TXT[userLanguage]}
                        </Typography>
                    </Grid>

                </Toolbar>


            </AppBar>
            <Container>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <Stack alignItems={"center"} >
                        <CircularProgress color="inherit" />
                    </Stack>

                </Backdrop>
                <Box sx={{ height: "69dvh", width: "100%" }} mt={1}>
                    {
                        Object.entries(consentList).map(([key, value], index) => {
                            return <Slide direction="left" in={currentPage === (index + 1)} mountOnEnter unmountOnExit>
                                <Card sx={{ overflow: "scroll", height: "100%", pl: 1, pr: 1 }} >
                                    <CardHeader title={<Typography variant='body2'>{value.company_name_th} ({value.company_name_en})</Typography>} />
                                    <CardContent >
                                        <Typography color={"InfoText"}>
                                            <div dangerouslySetInnerHTML={{ __html: (value["Consent_TH"]) }} />

                                        </Typography>

                                    </CardContent>
                                </Card>
                            </Slide>
                        })
                    }

                </Box>
                <Box>
                    <FormControlLabel control={<Checkbox checked={userAcceptTerm} onChange={(e) => setUserAcceptTerm(e.target.checked)} />} label={<Typography variant={"body2"}>{dict.Understand_And_Agree_TXT[userLanguage]}</Typography>} />

                </Box>
                <Box sx={{ textAlign: "center" }}>
                    {
                        consentList.length > 0 && <Stack spacing={2}>
                            <Typography variant={"caption"}> {dict.Page_TXT[userLanguage]} {currentPage}/{Object.keys(consentList)?.length} </Typography>
                            {
                                currentPage === Object.keys(consentList)?.length && <Button variant={"contained"} sx={{ height: 60 }} disabled={!userAcceptTerm} onClick={() => {
                                    // props?.accept()
                                    handleSubmitRegister()
                                }}>{dict.Register_Confirm_TXT[userLanguage]}</Button>
                            }
                            {
                                currentPage !== Object.keys(consentList)?.length && <Button variant={"contained"} size={"large"} disabled={!userAcceptTerm} onClick={() => {
                                    handleClickNext()
                                }}>{dict.Next_TXT[userLanguage]}</Button>
                            }

                        </Stack>
                    }
                </Box>



                <CardResponse
                    type={"success"}
                    titleDescription={`${dict.System_Saved_TXT[userLanguage]}`}
                    open={alertSuccess}
                    onClose={() => {
                        liff.closeWindow()
                    }}
                    onOk={() => liff.closeWindow()}
                />
                <CardResponse
                    type={"error"}
                    titleDescription={alertError.msg || `${dict.System_Error_TXT[userLanguage]}`}
                    open={alertError.open}
                    onClose={() => {
                        setAlertError(false)
                    }}
                    onOk={() => setAlertError(false)}
                />
                <CardResponse
                    type={"error"}
                    titleDescription={msgErrorConsent.msg}
                    open={msgErrorConsent.open}
                    onClose={() => {
                        setMsgErrorConsent({ msg: "", open: false })
                    }}
                    onOk={() => setMsgErrorConsent({ msg: "", open: false })}
                />
            </Container>
        </>

    )
}
