import {
    Stack,
    Card,
    Container,
    Typography,
    Box,
    Tabs,
    Tab,
    CardContent,
    Button
} from '@mui/material'
import { Call } from '@mui/icons-material';
import * as api from '../api/PhoneBook'
import { useEffect, useState, useContext } from 'react'
import { ProfileContext } from '../../src/contexts/ProfileContext'
import { BackDropLoading } from '../ui-component';
import * as dict from '../dictionary/PhoneBook'

export const PhoneBook = () => {

    const [profile, setProfile] = useState(null);
    const [houseInfo, setHouseInfo] = useState(null)
    const [contactList, setContactList] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [emergencyList, setEmergencyList] = useState([])
    const [isTabSelected, setIsTabSelected] = useState(0)
    const [loading, setLoading] = useState(false)
    const context = useContext(ProfileContext)

    const handleTabSelected = (newValue) => {
        window.scrollTo(0, 0);
        setIsTabSelected(newValue)
    }

    useEffect(() => {
        const loadPhoneBookList = async () => {
            setLoading(true)
            let res = await api.getPhoneBookList({
                "line_id": profile?.line_id,
                "product_type": houseInfo?.product_type,
                "project_id": houseInfo?.project_id
            })
            let data = res.data?.data
            setContactList(data.contact)
            setServiceList(data.service)
            setEmergencyList(data.emergency)
            setLoading(false)
        }
        if (houseInfo !== null) {
            loadPhoneBookList()
        }
    }, [profile, houseInfo]);

    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

        if (context?.state.houseInfo) {
            setHouseInfo(context?.state?.houseInfo)
        }
    }, [context])

    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js

    return (
        <>
            <div style={{ height: "calc(100vh - 108px)" }} >

                <Box sx={{ minWidth: "100%", position: "fixed", zIndex: 10 }}>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="fullWidth"
                        sx={{ backgroundColor: "white" }}
                        value={isTabSelected} onChange={(event, newValue) => handleTabSelected(newValue)} >
                        <Tab label={dict.Phone_Tab1_TXT[profile?.language]} />
                        <Tab label={dict.Phone_Tab2_TXT[profile?.language]} />
                        <Tab label={dict.Phone_Tab3_TXT[profile?.language]} />
                    </Tabs>
                </Box>

                <Container maxWidth="xl" sx={{ paddingTop : 10}}>

                    <Stack spacing={1}>
                        {
                            [emergencyList, contactList, serviceList][isTabSelected]?.map((element, index) => <Card variant="outlined" sx={{ minWidth: 275, borderRadius: "10px"}}>
                                <CardContent key={index}>
                                    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                                        <Stack direction="column"
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Typography variant="h6" >
                                                {element.title}
                                            </Typography>
                                            <Typography variant="body2" >
                                                {element.tel}
                                            </Typography>

                                        </Stack>
                                        <a href={`tel:${element.tel}`}><Button variant="contained"><Call /></Button></a>

                                    </Stack>

                                </CardContent>
                            </Card>)

                        }
                    </Stack>
                </Container>

                <BackDropLoading
                    loading={loading}
                />
            </div>
        </>
    )
}