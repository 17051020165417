import React, { useEffect, useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, Card, CardContent, Stack, Button} from '@mui/material'
import { Container } from '@mui/system'
import { ProfileContext } from '../../contexts/ProfileContext'
import { CardExpand } from '../../ui-component'
import { useLocation, useNavigate } from 'react-router-dom';
import * as dict from '../../dictionary'
import { checkProductType } from '../../utils/checkProductType'
import { BlankPage } from '../../ui-component'
export const ProjectDialog = () => {
    const context = useContext(ProfileContext)
    const [profile, setProfile] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const productType = checkProductType()
    const handleClickViewInfo = (val) => {
        navigate(`/profile/contractInfo${location.search}`,{
            state:  { contractDetail:  val } 
        })
    }

    const handleClickViewPrice = (val) => {
        navigate(`/profile/priceInfo${location.search}`,{
            state:  { priceInfoDetail:  val } 
        })
    }

    let params = new URLSearchParams(location.search);
    let is_show_price = params.get("isShowPrice")

        
    const preparedUnitSelf = () => {
        let unit = context.state?.profile?.unit
        if (unit === undefined) return
        let list = []
        for (const [key, value] of Object.entries(unit)) {
            list.push(<CardExpand
                key={key}
                titleCard={key.length > 20 ? key.substring(0, 20) + "..." : key}
                content={
                    value.map((element,i) => <Card key={i} sx={{ boxShadow: 0, minWidth: 275, borderRadius: "10px" }}>
                        <CardContent>
                            <Grid container >

                                <Stack direction="column"
                                    justifyContent="center"
                                    alignItems="flex-start"
                                    spacing={1}
                                    ml={2}
                                >
                                    <Typography variant="caption" >
                                        {productType ==="rental" ? dict.UnitNo_TXT[profile?.language] : dict.HouseNumber_TXT[profile?.language]} {productType ==="rental" ? element.unit_number : element.house_number}
                                    </Typography>

                                    <Stack alignItems="center" direction="row" spacing={1}>
                                        <Button
                                            variant="outlined" size="medium"
                                            onClick={() => handleClickViewInfo({ contract_id: element.contract_id , product_type: element.product_type, unit_id: element.unit_id , house_number: productType === "rental" ? element.unit_number : element.house_number})}>
                                            {dict.Contract_Detail_TXT[profile?.language]}
                                        </Button>
                                        {(is_show_price === "true" || is_show_price === null) && 
                                        <Button
                                            variant="outlined" size="medium"
                                            onClick={() => handleClickViewPrice({ product_type: element.product_type, unit_id: element.unit_id , house_number: productType === "rental" ? element.unit_number : element.house_number, project_type: element.project_type })}>
                                            {dict.Price_info_TXT[profile?.language]}
                                        </Button>
                                        }
                                    </Stack>

                                </Stack>

                            </Grid>

                        </CardContent>
                    </Card>

                    )}
            />)

        }
        
        if(list.length === 0)
        {
            return <BlankPage lang={profile?.language}/>
        } 
        return list
    }


    useEffect(() => {
        if (context.state?.profile !== null) {
            setProfile(context.state?.profile?.customer[0])
        }

    }, [context])

    return (
        <>
            <Box sx={{ marginTop: 10 }} >

                <Container>

                    <Stack spacing={1} direction={"column"} >

                        {

                            preparedUnitSelf()
                        }
                    </Stack>

                </Container>
            </Box>
        </>
    )
}

